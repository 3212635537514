import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lessonsActions } from '../../../../store/slices/lessonsSlice';
import PropTypes from 'prop-types';
import styles from '../Answer.module.scss';
import { TextField } from '@radix-ui/themes';

const InputAnswer = ({ userAnswerData }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const userAnswers = useSelector((state) => state.lessons.userAnswers);
  const prevAnswer = userAnswers[userAnswerData.taskIndex];
  const hasAnswer = Boolean(prevAnswer);

  useEffect(() => {
    setValue('');
    if (hasAnswer) {
      setValue(prevAnswer.text);
    }
  }, [hasAnswer, prevAnswer, userAnswerData.taskIndex]);

  const answerHandler = ({ target }) => {
    setValue(target.value);
    userAnswerData.text = target.value;
    dispatch(lessonsActions.selectAnswer(userAnswerData));
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor="inputAnswer">
        {userAnswerData.text || 'Введите ответ'}
      </label>
      <TextField.Root
        placeholder="Введите ответ"
        id="inputAnswer"
        value={value}
        onChange={answerHandler}
        autoComplete="off"
        type="text"
        size="3"
      />
    </div>
  );
};

InputAnswer.propTypes = {
  userAnswerData: PropTypes.shape({
    index: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    taskIndex: PropTypes.number.isRequired,
    taskNumber: PropTypes.number.isRequired,
  }).isRequired,
};

export default InputAnswer;


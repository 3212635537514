import { Link } from 'react-router-dom';
import styles from './ResultsTable.module.scss';
import PropTypes from 'prop-types';

const ResultsTableRow = ({ student, index }) => {
  return (
    <div className={styles.table__row}>
      <div className={styles.table__cell}>{index}</div>
      <div className={styles.table__cell}>
        <Link className={styles.link} to={`${student.id}`}>
          {student.name}
        </Link>
      </div>
      <div className={styles.table__cell}>{student.score && student.score + '%'}</div>
      <div className={styles.table__cell}>{student.grade || 'Нет оценки'}</div>
    </div>
  );
};

ResultsTableRow.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    score: PropTypes.number,
    grade: PropTypes.number,
  }),
  index: PropTypes.number.isRequired,
};

const getUserData = (userData) => ({
  id: userData.userId,
  name: `${userData.surname} ${userData.firstName} ${userData?.patronymic}`,
  score: userData.score,
  grade: userData.grade,
});

const ResultsTable = ({ data }) => {
  return (
    <div className={styles.table}>
      <div className={styles.table__row}>
        <div className={styles.table__cell}>#</div>
        <div className={styles.table__cell}>Студент</div>
        <div className={styles.table__cell}>Успеваемость</div>
        <div className={styles.table__cell}>Оценка</div>
      </div>

      {data.length === 0 ? (
        <div className={styles.table__empty}>Не удалось найти пользователей</div>
      ) : (
        data.map((user, index) => (
          <ResultsTableRow student={getUserData(user)} index={index + 1} key={index} />
        ))
      )}
    </div>
  );
};

ResultsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ResultsTable;


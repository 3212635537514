import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActiveSidebar: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.isActiveSidebar = action.payload;
    },
  },
});

export const { reducer: appReducer, actions: appActions } = appSlice;


import styles from './ContentList.module.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../../constants';
import clsx from 'clsx';
import toast, { Toaster } from 'react-hot-toast';

const ContentList = ({ list, createLink, getTitle }) => {
  const userPermissions = useSelector((state) => state.users.permissions);

  const isDisabled = (item) => {
    return item?.disabled && ![USER_ROLES.teacher, USER_ROLES.admin].includes(userPermissions);
  };

  const clickHandler = (item) => {
    if (isDisabled(item)) {
      toast.error('Вы уже дали ответ на задание.', { duration: 2000 });
    }
  };

  return (
    <>
      <ul className={styles.list}>
        {list.map((item, index) => (
          <li className={styles.item} key={index}>
            <Link
              className={clsx(styles.link, item?.disabled && styles.disabled)}
              to={isDisabled(item) ? '#' : createLink(item, index)}
              onClick={() => clickHandler(item)}
            >
              {getTitle(item)}
            </Link>
            <span className={styles.count}>{index + 1}</span>
          </li>
        ))}
      </ul>
      <Toaster position="bottom-right" />
    </>
  );
};

ContentList.propTypes = {
  list: PropTypes.array.isRequired,
  createLink: PropTypes.func.isRequired,
  getTitle: PropTypes.func.isRequired,
};

export default ContentList;


import styles from './LessonHeader.module.scss';
import ProgressBar from '../ProgressBar/ProgressBar';
import Question from '../Question/Question';
import PropTypes from 'prop-types';

const LessonHeader = ({ step, maxSteps, task }) => {
  return (
    <div className={styles.header}>
      <ProgressBar currentStep={step} maxSteps={maxSteps} />
      <Question task={task} />
    </div>
  );
};

LessonHeader.propTypes = {
  step: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  task: PropTypes.shape({
    question: PropTypes.string.isRequired,
    imgUrl: PropTypes.string,
  }).isRequired,
};

export default LessonHeader;


import { Box, Text } from '@radix-ui/themes';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import GroupsSelector from '../../components/Dashboard/Selectors/GroupsSelector';
import LoaderSpinnerContainer from '../../components/Loader/LoaderSpinnerContainer';
import ResultsTable from '../../components/Results/ResultsTable';
import { USER_ROLES } from '../../constants';
import lessonsApi from '../../store/api/lessonsApi';
import usersApi from '../../store/api/usersApi';

const LessonResultsPage = () => {
  const { lessonId } = useParams();
  const [groupId, setGroupId] = useState(null);
  const groupResults = usersApi.useGetGroupResultQuery(
    { lessonId, groupId },
    { skip: !Boolean(groupId) }
  );
  const lesson = lessonsApi.useGetLessonQuery(lessonId, { refetchOnFocus: false });

  if (lesson.isLoading || groupResults.isLoading) {
    return <LoaderSpinnerContainer />;
  }

  const filtredGroupResults = groupResults.data?.filter(({ role }) => role !== USER_ROLES.admin);

  const selectGroupHandler = ({ target }) => {
    setGroupId(target.value);
  };

  return (
    <div className="container">
      <h1 className="title">{lesson.data.lessonName}</h1>
      <Box maxWidth="300px" mb="3">
        <Text as="p" weight="medium" mb="1">
          Выберите группу
        </Text>
        <GroupsSelector
          onChange={selectGroupHandler}
          placeholder="Выберите группу"
          menuPosition="fixed"
          defaultValue={groupId}
        />
      </Box>
      <h2 className="subtitle">Текущая успеваемость занятия</h2>
      <ResultsTable data={filtredGroupResults || []} />
    </div>
  );
};

export default LessonResultsPage;


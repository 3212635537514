import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import Select from 'react-select';
import styles from './CustomSelect.module.scss';
import clsx from 'clsx';

/** * @type {React.FC<import('react-select').Props>} props */
const CustomSelect = forwardRef((props, ref) => {
  /* It is necessary for libraries such as `react-hook-form` to get `Select`
   attributes (name, value, etc.) via the default event interface  */
  const onChangeWithEvent = (option) => {
    const customEvent = { target: { name: props.name, value: option ? option.value : '' } };
    props?.onChange(customEvent, option);
  };

  return (
    <Select
      classNames={{
        container: () => styles.container,
        control: ({ selectProps, isFocused }) =>
          clsx(styles.control, { [styles.error]: selectProps.error && !isFocused }),
        option: ({ isSelected, isFocused }) =>
          clsx(styles.option, { [styles.selected]: isSelected, [styles.focused]: isFocused }),
        dropdownIndicator: () => styles.indicator,
        placeholder: () => styles.placeholder,
        clearIndicator: () => styles.clear__indicator,
        menu: () => styles.menu,
        menuList: () => styles.menu__list,
      }}
      ref={ref}
      {...props}
      onChange={onChangeWithEvent}
    />
  );
});

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default CustomSelect;


import { Skeleton } from '@radix-ui/themes';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import usersApi from '../../../store/api/usersApi';
import CustomSelect from '../../UI/CustomSelect/CustomSelect';
import { getDefaultSelectValue } from '../../../utils/form';

/** * @type {React.FC<import('react-select').Props>} props */
const GroupsSelector = forwardRef((props, ref) => {
  const { data, isLoading } = usersApi.useGetStudyGroupsQuery();
  const options = data?.map(({ id, nameRus }) => ({ value: id, label: nameRus }));

  const selectDefaultValue = getDefaultSelectValue(props.defaultValue, options);
  return (
    <Skeleton loading={isLoading}>
      <CustomSelect
        key={props.defaultValue}
        options={options}
        isClearable
        isSearchable
        isDisabled={isLoading}
        noOptionsMessage={() => 'Нет вариантов'}
        {...props}
        defaultValue={selectDefaultValue}
        ref={ref}
      />
    </Skeleton>
  );
});

GroupsSelector.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default GroupsSelector;


export const QUESTION_TYPES = {
  single: 'single',
  multiple: 'multiple',
  input: 'input',
  multipleInput: 'multipleInput',
};

export const QUESTION_TYPES_RUS = {
  single: 'Один вариант',
  multiple: 'Несколько вариантов',
  input: 'Ввод текста',
  multipleInput: 'Множественный ввод текста',
};

export const USER_DATA_STORAGE_KEY = 'userData';
export const AUTH_STORAGE_KEY = 'authData';
export const TOKEN_KEY = 'token';
export const LOGIN_KEY = 'login';

// Routes
export const COURSES_ROUTE = '/courses';
export const LESSON_ROUTE = '/lesson';
export const LOGIN_ROUTE = '/login';
export const SETTINGS_ROUTE = '/settings';
export const RESULTS_PAGE_ROUTE = '/results';
export const REGISTRATION_ROUTE = '/registration';
export const DASHBOARD_ROUTE = '/dashboard';
export const DASHBOARD_COURSES_ROUTE = '/dashboard/courses';
export const HOME_ROUTE = COURSES_ROUTE;

export const USER_ROLES = {
  admin: 'admin',
  teacher: 'teacher',
  student: 'student',
  guest: 'guest',
};

export const USER_ROLES_RUS = {
  admin: 'Администратор',
  teacher: 'Преподаватель',
  student: 'Студент',
  guest: 'Гость',
};

export const STATUSES = {
  active: 'active',
  hidden: 'hidden',
};

export const STATUSES_RUS = {
  active: 'Активный',
  hidden: 'Неактивный',
};


import { Link, useParams } from 'react-router-dom';
import Card from '../../components/UI/Card/Card';
import lessonsApi from '../../store/api/lessonsApi';
import coursesApi from '../../store/api/coursesApi';

const CourseResultsPage = () => {
  const { courseId } = useParams();
  const course = coursesApi.useGetCourseQuery(courseId, { refetchOnFocus: false });
  const lessons = lessonsApi.useGetLessonsQuery(
    { courseName: course.data?.courseTitle },
    {
      skip: !Boolean(course.data?.courseTitle),
      refetchOnFocus: false,
    }
  );

  return (
    <div className="container">
      <h1 className="title">{course.courseName}</h1>
      <h2 className="subtitle">Выберите занятие</h2>
      <ul className="card__grid">
        {lessons.data?.map(({ lessonId, lessonName }, index) => (
          <li key={index}>
            <Link to={`${lessonId}`} className="clear-link">
              <Card text={lessonName} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CourseResultsPage;


import ContentList from '../../components/ContentList/ContentList';
import styles from './CourseList.module.scss';
import { useSelector } from 'react-redux';
import coursesApi from '../../store/api/coursesApi';
import Loader from '../../components/Loader/Loader';

const createCourseLink = (course) => `${course.courseTitle}`;

const renderContent = (isLoading, isError, data) => {
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <div>Произошла ошибка</div>;
  }
  if (data?.length === 0) {
    return <div>Ничего не найдено</div>;
  }
  return <ContentList list={data} createLink={createCourseLink} getTitle={(item) => item.courseName} />;
};

const CourseList = () => {
  const groupId = useSelector((state) => state.users.userData.groupId);
  const filters = {
    status: 'active',
    group: groupId,
  };
  const courses = coursesApi.useGetCoursesQuery(filters, { refetchOnFocus: false });

  return (
    <div className={`${styles.wrapper} container`}>
      <div className={styles.window}>
        <h1 className={styles.title}>Список курсов</h1>
        {renderContent(courses.isLoading, courses.isError, courses.data)}
      </div>
    </div>
  );
};

export default CourseList;


import { useDispatch, useSelector } from 'react-redux';
import { lessonsActions } from '../store/slices/lessonsSlice';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const createInitalAnswer = (step, taskNumber) => ({
  index: 0,
  text: '',
  taskIndex: step,
  taskNumber,
});

const useSaveUserAnswer = ({ step, task }) => {
  const dispatch = useDispatch();
  const selectedAnswers = useSelector((state) => state.lessons.selectedAnswers);
  const userAnswers = useSelector((state) => state.lessons.userAnswers);
  const saveUserAnswer = useCallback(() => {
    const initialAnswer = createInitalAnswer(step, task?.taskNumber);

    if (!selectedAnswers || selectedAnswers.length === 0) {
      if (userAnswers.length === 0 || !userAnswers[step]) {
        // if user didn't give answer set placeholder (initialAnswer)
        dispatch(lessonsActions.setUserAnswers([...userAnswers, initialAnswer]));
      }
    } else {
      // if user gave answer - set anser, and then clear selected answers
      dispatch(lessonsActions.replaceUserAnswers({ index: step, newAnswer: selectedAnswers }));
      dispatch(lessonsActions.selectAnswer([]));
    }
  }, [dispatch, selectedAnswers, step, task?.taskNumber, userAnswers]);

  return saveUserAnswer;
};

useSaveUserAnswer.propTypes = {
  step: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
};

export default useSaveUserAnswer;


import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import AppRouter from './components/AppRouter';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { LOGIN_KEY } from './constants';
import usersApi from './store/api/usersApi';
import { authActions } from './store/slices/authSlice';
import { usersActions } from './store/slices/usersSlice';
import LoaderSpinnerContainer from './components/Loader/LoaderSpinnerContainer';

const App = () => {
  const userName = sessionStorage.getItem(LOGIN_KEY);
  const user = usersApi.useGetUserQuery(userName, { skip: !userName });
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);

  useEffect(() => {
    if (userName && user.data) {
      dispatch(usersActions.setUserData(user.data));
      dispatch(authActions.setAuth(true));
    } else {
      dispatch(authActions.setAuth(false));
    }
  }, [dispatch, user.data, userName]);

  if (user.isLoading) {
    return <LoaderSpinnerContainer />;
  }

  return (
    <div className={isAuth ? 'grid' : ''}>
      <Sidebar />
      <Header />
      <main className="app">
        <AppRouter />
      </main>
    </div>
  );
};

export default App;


import clsx from 'clsx';
import PropTypes from 'prop-types';
import Answer from './Answer/Answer';
import styles from './AnswerList.module.scss';

const AnswerList = ({ taskIndex, task }) => {
  const { taskType, answers } = task;
  const isMultipleInputType = taskType === 'multipleInput';

  return (
    <ul className={clsx(styles.list, isMultipleInputType && styles.list_multiple)}>
      {answers.map((answer, index) => {
        return (
          <li key={`${task.taskNumber}-${index}`}>
            <Answer
              text={answer}
              currentTaskNumber={task.taskNumber}
              index={index}
              taskType={taskType}
              taskIndex={taskIndex}
            />
          </li>
        );
      })}
    </ul>
  );
};

AnswerList.propTypes = {
  taskIndex: PropTypes.number.isRequired,
  task: PropTypes.shape({
    taskNumber: PropTypes.number.isRequired,
    taskType: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    imgUrl: PropTypes.string,
    lessonId: PropTypes.number.isRequired,
  }).isRequired,
};

export default AnswerList;


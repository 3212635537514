import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { adminRoutes, privateRoutes, publicRoutes, teacherRoutes } from '../router/router';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LOGIN_ROUTE, USER_ROLES } from '../constants';

const renderRoutes = (routes) =>
  routes.map(({ path, element }) => <Route path={path} element={element} key={path} />);

const PrivateRoute = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const location = useLocation();
  return isAuth ? <Outlet /> : <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
};

const AppRouter = () => {
  const userPermissions = useSelector((state) => state.users.permissions);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        {renderRoutes(privateRoutes)}
        {userPermissions === USER_ROLES.admin && renderRoutes(adminRoutes)}
        {userPermissions === USER_ROLES.teacher && renderRoutes(teacherRoutes)}
      </Route>
      {renderRoutes(publicRoutes)}
    </Routes>
  );
};

export default AppRouter;


import { createSlice } from '@reduxjs/toolkit';
import { LOGIN_KEY, TOKEN_KEY } from '../../constants';

const initialState = {
  isAuth: false,
};

function clearStorage() {
  sessionStorage.removeItem(LOGIN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },

    logout: (state) => {
      state.isAuth = false;
      state.userData = {};
      clearStorage();
    },
  },
});

export const { reducer: authReducer, actions: authActions } = authSlice;


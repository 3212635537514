import styles from './Card.module.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Card = ({ text, subTitle, size = 'md', DropdownMenu }) => {
  return (
    <div className={clsx(styles.card, size === 'sm' && styles.card_sm)}>
      <div className={styles.card__text}>{text}</div>
      <div className={styles.card__footer}>
        <span className={styles.card__subtitle}>{subTitle}</span>
        {DropdownMenu && DropdownMenu}
      </div>
    </div>
  );
};

Card.propTypes = {
  text: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  size: PropTypes.string,
  DropdownMenu: PropTypes.element,
};

export default Card;


import { createSlice } from '@reduxjs/toolkit';
import { USER_ROLES } from '../../constants';

function getUserPermissions(userRole) {
  if (userRole === USER_ROLES.teacher) return USER_ROLES.teacher;
  if (userRole === USER_ROLES.admin) return USER_ROLES.admin;
  return USER_ROLES.student;
}

const initialState = {
  userData: {},
  permissions: '',
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      const role = action.payload.role;
      if (role) {
        state.permissions = getUserPermissions(role);
      }
    },
  },
});

export const { reducer: usersReducer, actions: usersActions } = usersSlice;


import { useCallback, useState } from 'react';
import styles from './Question.module.scss';
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import PropTypes from 'prop-types';

const ImagePreview = ({ imgUrl }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <img
        src={imgUrl}
        className={styles.img}
        onClick={() => openImageViewer(0)}
        alt=""
        draggable={false}
      />
      {isViewerOpen && (
        <ReactSimpleImageViewer
          src={[imgUrl]}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            paddingBlock: '50px',
            zIndex: 1500,
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

ImagePreview.propTypes = {
  imgUrl: PropTypes.string.isRequired,
};

const Question = ({ task }) => {
  return (
    <>
      <h1 className={styles.title}>{task.question}</h1>
      {task.imgUrl && (
        <div className={styles.img__wrapper}>
          <ImagePreview imgUrl={task.imgUrl} />
        </div>
      )}
    </>
  );
};

Question.propTypes = {
  task: PropTypes.shape({
    question: PropTypes.string.isRequired,
    imgUrl: PropTypes.string,
  }).isRequired,
};

export default Question;


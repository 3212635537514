import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/slices/authSlice';
// import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow-left.svg';
import { HOME_ROUTE, LOGIN_ROUTE } from '../../constants';
import BurgerButton from '../BurgerButton/BurgerButton';
import { warningHandler } from '../Sidebar/Sidebar';
import { Button } from '@radix-ui/themes';

const BackButton = () => {
  const hasStarted = useSelector((state) => state.lessons.hasStarted);
  const navigate = useNavigate();

  const clickHandler = (event) => {
    const result = warningHandler(event, hasStarted);
    if ((hasStarted && result) || !hasStarted) {
      navigate(-1);
    }
  };

  return (
    <Button variant="ghost" size="3" radius="small" onClick={clickHandler} title="Вернуться назад">
      <ArrowIcon />
    </Button>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const location = useLocation();
  const isHomePage = location.pathname === HOME_ROUTE;
  const navigate = useNavigate();

  function logout() {
    dispatch(authActions.logout());
    navigate(LOGIN_ROUTE, { replace: true });
  }

  return (
    <header className={styles.header}>
      <div className={styles.header__left}>
        {isAuth && <BurgerButton />}
        {!isAuth && <h2>Электронная тетрадь</h2>}
        {!isHomePage && isAuth && <BackButton />}
        {/* {isAuth && <Breadcrumbs />} */}
      </div>
      <div className={styles.header__right}>
        {isAuth && (
          <Button size="2" title="Выйти из аккаунта" onClick={() => logout()}>
            Выйти
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;


import { TailSpin } from 'react-loader-spinner';
import styles from './Loader.module.scss';
import PropTypes from 'prop-types';

const LoaderSpinnerContainer = ({ withText = true, ...props }) => {
  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <TailSpin
          visible={true}
          height="60"
          width="60"
          color="#114968"
          ariaLabel="tail-spin-loading"
          radius="1"
          {...props}
        />
      </div>
      {withText && <div>Загрузка...</div>}
    </div>
  );
};

LoaderSpinnerContainer.propTypes = {
  withText: PropTypes.bool,
};

export default LoaderSpinnerContainer;


import Loader from './Loader';
import styles from './Loader.module.scss';
import PropTypes from 'prop-types';

const LoaderContainer = ({ withText = true }) => {
  return (
    <div className={styles.container}>
      <Loader />
      {withText && <div>Загрузка...</div>}
    </div>
  );
};

LoaderContainer.propTypes = {
  withText: PropTypes.bool,
};

export default LoaderContainer;


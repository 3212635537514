import { useEffect } from 'react';

/** * @param {Boolean} preventCondition */
export function usePreventReloadPage(preventCondition) {
  useEffect(() => {
    /** * @param {BeforeUnloadEvent} event */
    const reloadPageHandler = (event) => {
      event.preventDefault();
    };

    if (preventCondition) {
      window.addEventListener('beforeunload', reloadPageHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', reloadPageHandler);
    };
  }, [preventCondition]);
}


import styles from './BurgerButton.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../store/slices/appSlice';
import clsx from 'clsx';

const BurgerButton = () => {
  const dispatch = useDispatch();
  const isActiveSidebar = useSelector((state) => state.app.isActiveSidebar);

  const toggleMenu = () => {
    dispatch(appActions.toggleSidebar(!isActiveSidebar));
  };

  return (
    <button className={clsx(styles.burger__btn, isActiveSidebar && styles.active)} onClick={toggleMenu}>
      <span>Menu</span>
    </button>
  );
};

export default BurgerButton;


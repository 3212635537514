import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { USERS_API_URL, UsersAPI } from '../../api/usersApi';

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ url: USERS_API_URL }),
  endpoints: (build) => ({
    getStudyGroups: build.query({
      queryFn: UsersAPI.getStudyGroups,
    }),
    getUser: build.query({
      queryFn: UsersAPI.getUser,
    }),
    getLessonResult: build.query({
      queryFn: UsersAPI.getLessonResult,
    }),
    getGroupResult: build.query({
      queryFn: UsersAPI.getGroupResult,
    }),
    getRoles: build.query({
      queryFn: UsersAPI.getRoles,
      providesTags: ['roles'],
    }),
  }),
});

export default usersApi;


import { useState } from 'react';
import LessonResult from '../LessonResult/LessonResult';
import LessonHeader from '../LessonHeader/LessonHeader';
import AnswerList from '../AnswerList/AnswerList';
import LessonFooter from '../LessonFooter/LessonFooter';
import styles from './LessonSession.module.scss';
import PropTypes from 'prop-types';
import { usePreventReloadPage } from '../../hooks/usePreventReloadPage';
import useSaveUserAnswer from '../../hooks/useSaveUserAnswer';

const LessonSession = ({ lessonTasks }) => {
  const [step, setStep] = useState(0);
  const taskCount = lessonTasks.length;
  const isLessonEnd = () => step > 0 && step === taskCount;
  const task = lessonTasks[step] || null;
  const saveUserAnswer = useSaveUserAnswer({ step, task });

  usePreventReloadPage(!isLessonEnd());

  if (taskCount === 0) {
    return <div>Задания не найдены</div>;
  }

  return (
    <div className={styles.window}>
      {isLessonEnd() ? (
        <LessonResult />
      ) : (
        <>
          <LessonHeader step={step} maxSteps={taskCount} task={task} />
          <AnswerList taskIndex={step} task={task} />
        </>
      )}
      <LessonFooter step={step} setStep={setStep} tasksCount={taskCount} saveHandler={saveUserAnswer} />
    </div>
  );
};

LessonSession.propTypes = {
  lessonTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LessonSession;


import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Flex, Text } from '@radix-ui/themes';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import InputField from '../../UI/InputField';
import { schema } from './validationSchema';

const LoginForm = ({ onSubmit, defaultValues, isLoading, isError }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode: 'onChange', resolver: zodResolver(schema) });

  return (
    <form id="loginForm" onSubmit={handleSubmit((e) => onSubmit(e, reset))}>
      <Flex direction="column" gap="4">
        <InputField
          label="Логин"
          name="userName"
          control={control}
          errors={errors}
          placeholder="Введите логин"
          autoComplete="off"
          size="3"
          radius="medium"
        />
        <InputField
          label="Пароль"
          name="password"
          control={control}
          errors={errors}
          placeholder="Введите пароль"
          autoComplete="off"
          type="password"
          size="3"
          radius="medium"
        />
      </Flex>
      <Button mt="4" size="3" radius="medium" type="submit" loading={isLoading}>
        Войти
      </Button>
      {isError && (
        <Box mt="2">
          <Text color="red" size="2">
            Неверный логин или пароль
          </Text>
        </Box>
      )}
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default LoginForm;


import { authClient } from './api';

export const USERS_API_URL = 'users';

export class UsersAPI {
  static async getUser(userName) {
    try {
      const response = await authClient.get(`${USERS_API_URL}/${userName}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async getStudyGroups() {
    try {
      const response = await authClient.get(`${USERS_API_URL}/groups`);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async getLessonResult({ lessonId, userId }) {
    try {
      const response = await authClient.get(`${USERS_API_URL}/result/${userId}?lesson=${lessonId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getGroupResult({ lessonId, groupId }) {
    try {
      const response = await authClient.get(
        `${USERS_API_URL}/result/group/${groupId}?lesson=${lessonId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getRoles() {
    try {
      const response = await authClient.get(`${USERS_API_URL}/roles`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}


import AnswerButton from '../../../UI/AnswerButton/AnswerButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { lessonsActions } from '../../../../store/slices/lessonsSlice';
import { useCheckIsAnsweredTask } from '../../../../hooks/useCheckAnsweredTask';

const MultipleAnswer = ({ userAnswerData, taskType, ...props }) => {
  const dispatch = useDispatch();
  const selectedAnswers = useSelector((state) => state.lessons.selectedAnswers);
  const answerIndex = userAnswerData.index;

  const isHighlightedAnswer = useCheckIsAnsweredTask({
    taskType,
    taskIndex: userAnswerData.taskIndex,
    answerIndex: userAnswerData.index,
  });

  const answerHandler = () => {
    const isSelectedAnswer = selectedAnswers?.some(({ index }) => index === answerIndex);

    if (isSelectedAnswer) {
      const newAnswers = selectedAnswers.filter(({ index }) => index !== answerIndex);
      const answer = newAnswers.length > 0 ? newAnswers : [];

      dispatch(lessonsActions.selectAnswer(answer));
    } else {
      dispatch(lessonsActions.selectAnswer([...selectedAnswers, userAnswerData]));
    }
  };

  return (
    <AnswerButton
      type="checkbox"
      changeHandler={answerHandler}
      isChecked={isHighlightedAnswer}
      {...props}
    />
  );
};

MultipleAnswer.propTypes = {
  userAnswerData: PropTypes.shape({
    index: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    taskIndex: PropTypes.number.isRequired,
    taskNumber: PropTypes.number.isRequired,
  }).isRequired,
  taskType: PropTypes.string.isRequired,
};

export default MultipleAnswer;


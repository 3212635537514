import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  COURSES_ROUTE,
  DASHBOARD_COURSES_ROUTE,
  DASHBOARD_ROUTE,
  LESSON_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  RESULTS_PAGE_ROUTE,
  SETTINGS_ROUTE,
} from '../constants';
import LazyComponent from '../hoc/LazyComponent.jsx';
import CourseList from '../pages/CourseList/CourseList';
import LessonPage from '../pages/LessonPage/LessonPage';
import LessonList from '../pages/LessonList/LessonList';
import CourseResultsPage from '../pages/ResultsPage/CourseResultsPage.jsx';
import LessonResultsPage from '../pages/ResultsPage/LessonResultsPage.jsx';
import LoginPage from '../pages/LoginPage/LoginPage.jsx';

const LazySettingsPage = lazy(() => import('../pages/SettingsPage/SettingsPage.jsx'));
const LazyResultsPage = lazy(() => import('../pages/ResultsPage/ResultsPage.jsx'));
const LazyUserResultsPage = lazy(() => import('../pages/UserResultsPage/UserResultsPage.jsx'));
const LazyUserRegistrationPage = lazy(() =>
  import('../pages/UserRegistrationPage/UserRegistrationPage.jsx')
);
const LazyDashboardPage = lazy(() => import('../pages/DashboardPage/DashboardPage.jsx'));
const LazyDashboardCoursesPage = lazy(() => import('../pages/DashboardPage/DashboardCoursesPage.jsx'));
const LazyDashboardCoursePage = lazy(() => import('../pages/DashboardPage/DashboardCoursePage.jsx'));
const LazyDashboardLessonPage = lazy(() => import('../pages/DashboardPage/DashboardLessonPage.jsx'));
const LazyDashboardTaskPage = lazy(() => import('../pages/DashboardPage/DashboardTaskPage.jsx'));

const privateRoutes = [
  {
    path: COURSES_ROUTE,
    element: <CourseList />,
  },
  {
    path: `${COURSES_ROUTE}/:courseName`,
    element: <LessonList />,
  },
  {
    path: `${COURSES_ROUTE}/:courseName${LESSON_ROUTE}/:lessonId`,
    element: <LessonPage />,
  },

  {
    path: SETTINGS_ROUTE,
    element: (
      <LazyComponent>
        <LazySettingsPage />
      </LazyComponent>
    ),
  },
];

const teacherRoutes = [
  {
    path: RESULTS_PAGE_ROUTE,
    element: (
      <LazyComponent>
        <LazyResultsPage />
      </LazyComponent>
    ),
  },
  {
    path: `${RESULTS_PAGE_ROUTE}/:courseId`,
    element: <CourseResultsPage />,
  },
  {
    path: `${RESULTS_PAGE_ROUTE}/:courseId/:lessonId`,
    element: <LessonResultsPage />,
  },
  {
    path: `${RESULTS_PAGE_ROUTE}/:courseId/:lessonId/:userId`,
    element: (
      <LazyComponent>
        <LazyUserResultsPage />
      </LazyComponent>
    ),
  },
  {
    path: DASHBOARD_ROUTE,
    element: (
      <LazyComponent>
        <LazyDashboardPage />
      </LazyComponent>
    ),
  },
  {
    path: DASHBOARD_COURSES_ROUTE,
    element: (
      <LazyComponent>
        <LazyDashboardCoursesPage />
      </LazyComponent>
    ),
  },
  {
    path: `${DASHBOARD_COURSES_ROUTE}/:courseId`,
    element: (
      <LazyComponent>
        <LazyDashboardCoursePage />
      </LazyComponent>
    ),
  },
  {
    path: `${DASHBOARD_COURSES_ROUTE}/:courseId/lesson/:lessonId`,
    element: (
      <LazyComponent>
        <LazyDashboardLessonPage />
      </LazyComponent>
    ),
  },
  {
    path: `${DASHBOARD_COURSES_ROUTE}/:courseId/lesson/:lessonId/task/:taskId`,
    element: (
      <LazyComponent>
        <LazyDashboardTaskPage />
      </LazyComponent>
    ),
  },
];

const adminRoutes = [
  {
    path: REGISTRATION_ROUTE,
    element: (
      <LazyComponent>
        <LazyUserRegistrationPage />
      </LazyComponent>
    ),
  },
  ...teacherRoutes,
];

const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <Navigate to={LOGIN_ROUTE} replace />,
  },
];

export { adminRoutes, privateRoutes, publicRoutes, teacherRoutes };


/**
 * @param {string|null} initialValue
 * @param {Array} options
 * @returns {null|{value: string, label: string}}
 */
export function getDefaultSelectValue(initialValue, options) {
  if (initialValue && options && options.length > 0) {
    return options.find((option) => option.value.toString() === initialValue.toString());
  }
  return null;
}

import { makeFilterQueryString } from '../utils/utils';
import { authClient } from './api';

export const LESSONS_URL = 'lessons';

export class LessonAPI {
  /**
   * @param {number} lessonId
   * @returns {Promise<Object>}
   *  */
  static async getLessonById(lessonId) {
    try {
      const response = await authClient.get(`${LESSONS_URL}/${lessonId}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @param {string} courseName
   * @returns {Promise<Object>}
   * */
  static async getLessons({ courseName, filters = {} }) {
    const queryString = makeFilterQueryString(filters);
    try {
      const response = await authClient.get(`${LESSONS_URL}?course=${courseName}${queryString}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  static async getLessonAnswers(lessonId) {
    try {
      const response = await authClient.get(`${LESSONS_URL}/${lessonId}/answers`);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  static async getGroupResult({ lessonId, groupId }, controller) {
    try {
      return await authClient.get(`${LESSONS_URL}/group/${lessonId}?group=${groupId}`, {
        signal: controller?.signal,
      });
    } catch (error) {
      throw error;
    }
  }

  static async getLessonResult(lessonData) {
    try {
      return await authClient.post(`${LESSONS_URL}/result`, lessonData);
    } catch (error) {
      throw error;
    }
  }

  /**
   *
   * @param {{lessonName: string, status: string, lessonNumber: number, courseId: number}} lessonData
   * @returns {Promise}
   */
  static async createLesson(lessonData) {
    try {
      return await authClient.post(`${LESSONS_URL}`, lessonData);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{lessonName: string, status: string}} lessonData
   * @returns {Promise}
   */
  static async updateLesson(lessonData) {
    try {
      return await authClient.put(`${LESSONS_URL}/${lessonData.lessonId}`, lessonData);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {number} lessonId
   * @returns {Promise}
   */
  static async deleteLesson(lessonId) {
    try {
      return await authClient.delete(`${LESSONS_URL}/${lessonId}`);
    } catch (error) {
      throw error;
    }
  }
}


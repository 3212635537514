import PropTypes from 'prop-types';
import { formatFormula, isFormula } from '../../../utils/utils';
import { QUESTION_TYPES } from '../../../constants';
import SingleAnswer from './SingleAnswer/SingleAnswer';
import InputAnswer from './InputAnswer/InputAnswer';
import MultipleInputAnswer from './MultipleInputAnswer/MultipleInputAnswer';
import MultipleAnswer from './MultipleAnswer/MultipleAnswer';

const Answer = ({ text, index, taskType, taskIndex, currentTaskNumber }) => {
  const value = isFormula(text) ? formatFormula(text) : text;
  const userAnswerData = { index, text, taskIndex, taskNumber: currentTaskNumber };
  const answerProps = { name: 'answer', index, value };

  if (taskType === QUESTION_TYPES.single) {
    return <SingleAnswer userAnswerData={userAnswerData} taskType={taskType} {...answerProps} />;
  }
  if (taskType === QUESTION_TYPES.multiple) {
    return <MultipleAnswer userAnswerData={userAnswerData} taskType={taskType} {...answerProps} />;
  }
  if (taskType === QUESTION_TYPES.input) {
    return <InputAnswer userAnswerData={userAnswerData} />;
  }
  if (taskType === QUESTION_TYPES.multipleInput) {
    return <MultipleInputAnswer userAnswerData={userAnswerData} />;
  }
};

Answer.propTypes = {
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  taskType: PropTypes.string.isRequired,
  taskIndex: PropTypes.number.isRequired,
  currentTaskNumber: PropTypes.number.isRequired,
};

export default Answer;


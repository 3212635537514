import { CoursesAPI, COURSES_URL } from '../../api/coursesApi';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const coursesApi = createApi({
  reducerPath: 'coursesApi',
  baseQuery: fetchBaseQuery({ url: COURSES_URL }),
  endpoints: (build) => ({
    getCourses: build.query({
      queryFn: CoursesAPI.getCourses,
      providesTags: ['courses'],
    }),
    getGroupCourses: build.query({
      queryFn: CoursesAPI.getGroupCourses,
      providesTags: ['courses'],
    }),

    getEnrolledGroups: build.query({
      queryFn: CoursesAPI.getEnrolledGroups,
      providesTags: ['enrolledGroups'],
    }),
    enrollGroup: build.mutation({
      queryFn: CoursesAPI.enrollGroup,
      invalidatesTags: ['enrolledGroups'],
    }),
    excludeGroup: build.mutation({
      queryFn: CoursesAPI.excludeGroup,
      invalidatesTags: ['enrolledGroups'],
    }),
    getCourse: build.query({
      queryFn: CoursesAPI.getCourse,
      providesTags: ['course'],
    }),
    createCourse: build.mutation({
      queryFn: CoursesAPI.createCourse,
      invalidatesTags: ['courses'],
    }),
    updateCourse: build.mutation({
      queryFn: CoursesAPI.updateCourse,
      invalidatesTags: ['courses', 'course'],
    }),
    deleteCourse: build.mutation({
      queryFn: CoursesAPI.deleteCourse,
      invalidatesTags: ['courses'],
    }),
  }),
});

export default coursesApi;


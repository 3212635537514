import { useSelector } from 'react-redux';
import { QUESTION_TYPES } from '../constants';

/**
 * @param {{taskType: string, taskIndex: number }}
 * @returns {bool}
 */
export function useCheckIsAnsweredTask({ taskType, taskIndex, answerIndex }) {
  const userAnswers = useSelector((state) => state.lessons.userAnswers);
  const selectedAnswers = useSelector((state) => state.lessons.selectedAnswers);
  const currentTaskAnswers = userAnswers[taskIndex];
  const isSingleAnswer = taskType === QUESTION_TYPES.single;
  const isMultipleAnswer = taskType === QUESTION_TYPES.multiple;

  let isHighlightTask = false;

  const hasAnswers = userAnswers.length > 0;

  if (isSingleAnswer) {
    if (!hasAnswers || !currentTaskAnswers || !currentTaskAnswers.text) {
      isHighlightTask = false;
      return isHighlightTask;
    }

    const userAnswerIndexes = [currentTaskAnswers.index];
    const selectedAnswerIndexes = !Array.isArray(selectedAnswers) ? [selectedAnswers.index] : [];
    const isMatch = userAnswerIndexes.every((index) => selectedAnswerIndexes.includes(index));
    const isPrevAnswer = userAnswerIndexes.includes(answerIndex);
    const isNewAnswer = selectedAnswerIndexes.includes(answerIndex);

    if (selectedAnswerIndexes.length > 0 && Boolean(selectedAnswers)) {
      isHighlightTask = isMatch ? isPrevAnswer : isNewAnswer;
    } else {
      isHighlightTask = isPrevAnswer;
    }
    return isHighlightTask;
  }

  if (isMultipleAnswer) {
    if (!hasAnswers || !Array.isArray(currentTaskAnswers)) {
      isHighlightTask = false;
      return isHighlightTask;
    }
    const userAnswerIndexes = currentTaskAnswers.map(({ index }) => index);
    const selectedAnswerIndexes = selectedAnswers.map(({ index }) => index);
    const isMatch = userAnswerIndexes.every((index) => selectedAnswerIndexes.includes(index));
    const isPrevAnswer = userAnswerIndexes.includes(answerIndex);
    const isNewAnswer = selectedAnswerIndexes.includes(answerIndex);

    if (selectedAnswerIndexes.length > 0 && Boolean(selectedAnswers)) {
      isHighlightTask = isMatch ? isPrevAnswer : isNewAnswer;
    } else {
      isHighlightTask = isPrevAnswer;
    }
    return isHighlightTask;
  }
}


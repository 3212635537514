import { Button } from '@radix-ui/themes';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE, USER_ROLES, USER_ROLES_RUS } from '../../constants';
import { authActions } from '../../store/slices/authSlice';
import styles from './Sidebar.module.scss';
import SidebarHeader from './SidebarHeader';
import SidebarItem from './SidebarItem';
import { ADMIN_LINKS, TEACHER_LINKS, USER_LINKS } from './routes';

export const warningHandler = (event, condition) => {
  if (condition) {
    const result = window.confirm('Вы уверены, что хотите уйти? Ваш прогресс будет потерян.');
    if (!result) {
      event.preventDefault();
    }
    return result;
  }
};

const renderItems = (userRole) => {
  const items = [...USER_LINKS];

  if (userRole === USER_ROLES.teacher || userRole === USER_ROLES.admin) {
    TEACHER_LINKS.forEach((link) => {
      items.push(link);
    });
  }
  if (userRole === USER_ROLES.admin) {
    ADMIN_LINKS.forEach((link) => {
      items.push(link);
    });
  }
  return items.map(({ title, path, icon }) => (
    <SidebarItem key={title} name={title} path={path} Icon={icon} />
  ));
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userData = useSelector((state) => state.users.userData);
  const isActiveSidebar = useSelector((state) => state.app.isActiveSidebar);
  const navigate = useNavigate();
  const { firstName, surname, patronymic, role: userRole } = userData;
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  if (!isAuth) {
    return null;
  }

  const userName = `${surname} ${firstName} ${patronymic}`;

  function logout() {
    dispatch(authActions.logout());
    navigate(LOGIN_ROUTE, { replace: true });
  }

  return (
    <aside className={clsx(styles.sidebar, isActiveSidebar && styles.active)}>
      <div className={styles.sidebar__wrapper}>
        <SidebarHeader
          userName={userName}
          userRole={USER_ROLES_RUS[userRole] || 'Студент'}
          isActive={isActiveSidebar}
        />
        <nav className={styles.nav}>
          <ul className={styles.nav__list}>{renderItems(userRole)}</ul>
          {isMobile && (
            <Button size="2" title="Выйти из аккаунта" onClick={() => logout()}>
              Выйти
            </Button>
          )}
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;


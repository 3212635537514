import { makeFilterQueryString } from '../utils/utils';
import { authClient } from './api';

export const COURSES_URL = 'courses';

export class CoursesAPI {
  /** * @param {string} courseName * @returns {Promise} */
  static async getCourse(courseName) {
    try {
      const response = await authClient.get(`${COURSES_URL}/${courseName}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {Object} filters
   * @returns {Promise}
   */
  static async getCourses(filters = {}) {
    const queryString = makeFilterQueryString(filters);
    try {
      const response = await authClient.get(`${COURSES_URL}?${queryString}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /** * @param {number} groupId * @returns {Promise} */
  static async getGroupCourses(groupId) {
    try {
      const response = await authClient.get(`${COURSES_URL}?group=${groupId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {number} courseId
   * @returns {Promise}
   */
  static async getEnrolledGroups(courseId) {
    try {
      const response = await authClient.get(`${COURSES_URL}/${courseId}/groups`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{courseId: number, groupId: string}}
   * @returns {Promise}
   */
  static async enrollGroup({ courseId, groupId }) {
    try {
      const response = await authClient.post(`${COURSES_URL}/${courseId}/groups`, { groupId });
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{courseId: number, groupId: string}}
   * @returns {Promise}
   */
  static async excludeGroup({ courseId, groupId }) {
    try {
      const response = await authClient.delete(`${COURSES_URL}/${courseId}/groups/${groupId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{courseId: number, courseName: string, courseTitle: string, status: string}} courseData
   * @returns {Promise}
   */
  static async createCourse(courseData) {
    try {
      const response = await authClient.post(`${COURSES_URL}`, courseData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{courseId: number, courseName: string, courseTitle: string, status: string}} courseData
   * @returns {Promise}
   */
  static async updateCourse(courseData) {
    try {
      return await authClient.put(`${COURSES_URL}/${courseData.id}`, courseData);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {number} courseId
   * @returns {Promise}
   */
  static async deleteCourse(courseId) {
    try {
      return await authClient.delete(`${COURSES_URL}/${courseId}`);
    } catch (error) {
      throw error;
    }
  }
}


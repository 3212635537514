import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { lessonsActions } from '../../../../store/slices/lessonsSlice';
import AnswerButton from '../../../UI/AnswerButton/AnswerButton';
import { useCheckIsAnsweredTask } from '../../../../hooks/useCheckAnsweredTask';

const SingleAnswer = ({ userAnswerData, taskType, ...props }) => {
  const dispatch = useDispatch();
  const isHighlightedAnswer = useCheckIsAnsweredTask({
    taskType,
    taskIndex: userAnswerData.taskIndex,
    answerIndex: userAnswerData.index,
  });

  const answerHandler = () => {
    dispatch(lessonsActions.selectAnswer(userAnswerData));
  };

  return (
    <AnswerButton
      type="radio"
      changeHandler={answerHandler}
      isChecked={isHighlightedAnswer}
      {...props}
    />
  );
};

SingleAnswer.propTypes = {
  userAnswerData: PropTypes.shape({
    index: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    taskIndex: PropTypes.number.isRequired,
    taskNumber: PropTypes.number.isRequired,
  }).isRequired,
  taskType: PropTypes.string.isRequired,
};

export default SingleAnswer;


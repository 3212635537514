import { configureStore } from '@reduxjs/toolkit';
import coursesApi from './api/coursesApi.js';
import lessonsApi from './api/lessonsApi.js';
import tasksApi from './api/tasksApi.js';
import usersApi from './api/usersApi.js';
import { appReducer } from './slices/appSlice.js';
import { authReducer } from './slices/authSlice.js';
import { lessonsReducer } from './slices/lessonsSlice.js';
import { usersReducer } from './slices/usersSlice.js';
import authApi from './api/authApi.js';

const store = configureStore({
  reducer: {
    app: appReducer,
    users: usersReducer,
    auth: authReducer,
    lessons: lessonsReducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [lessonsApi.reducerPath]: lessonsApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      usersApi.middleware,
      coursesApi.middleware,
      lessonsApi.middleware,
      tasksApi.middleware
    ),
});

export default store;


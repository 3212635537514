import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../store/slices/appSlice';
import { warningHandler } from './Sidebar';

const activeLink = `${styles.nav__link} ${styles.nav__link_active}`;

const SidebarItem = ({ name, path, Icon }) => {
  const dispatch = useDispatch();
  const hasStarted = useSelector((state) => state.lessons.hasStarted);

  /** * @param {MouseEvent} event */
  const clickHandler = (event) => {
    dispatch(appActions.toggleSidebar(false));
    warningHandler(event, hasStarted);
  };

  return (
    <li className={styles.nav__item}>
      <NavLink
        className={({ isActive }) => (isActive ? activeLink : styles.nav__link)}
        to={path}
        onClick={clickHandler}
      >
        <Icon className={styles.nav__icon} width="22" height="22" />
        <span>{name}</span>
      </NavLink>
    </li>
  );
};

SidebarItem.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  Icon: PropTypes.elementType,
};

export default SidebarItem;


import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';
import BurgerButton from '../BurgerButton/BurgerButton';

const SidebarHeader = ({ userName, userRole, isActive }) => {
  return (
    <div className={styles.sidebar__header}>
      <p className={styles.sidebar__name}>{userName}</p>
      <p className={styles.sidebar__role}>{userRole}</p>

      {isActive && (
        <div className={styles.close}>
          <BurgerButton />
        </div>
      )}
    </div>
  );
};

SidebarHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default SidebarHeader;


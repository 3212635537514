import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './LessonPage.module.scss';
import Instruments from '../../components/Instruments/Instruments';
import { useDispatch } from 'react-redux';
import { lessonsActions } from '../../store/slices/lessonsSlice';
import LessonSession from '../../components/LessonSession/LessonSession';
import tasksApi from '../../store/api/tasksApi';
import LoaderContainer from '../../components/Loader/LoaderContainer';

const LessonPage = () => {
  const dispatch = useDispatch();
  const { lessonId } = useParams();
  const lessonTasks = tasksApi.useGetTasksQuery(lessonId, { refetchOnFocus: false });

  useEffect(() => {
    if (lessonTasks.data?.length > 0) {
      dispatch(lessonsActions.setLessonStatus(true));
    }

    return () => {
      if (lessonTasks.data?.length > 0) {
        dispatch(lessonsActions.refreshLesson());
      }
    };
  }, [dispatch, lessonTasks.data]);

  const renderContent = () => {
    if (lessonTasks.isError) {
      return <div>Что-то пошло не так.</div>;
    }
    if (lessonTasks.isLoading) {
      return <LoaderContainer withText={false} />;
    }
    if (lessonTasks.data?.length === 0) {
      return 'У вас нет доступных заданий';
    }
    return (
      <>
        <LessonSession lessonTasks={lessonTasks.data} />
        <Instruments />
      </>
    );
  };
  return <div className={`${styles.wrapper} container`}>{renderContent()}</div>;
};

export default LessonPage;


import { Box, Text, TextField } from '@radix-ui/themes';
import PropTypes from 'prop-types';

/** * @type {React.FC<HTMLInputElement>} props */
const InputField = ({ label, name, control, ...options }) => {
  const { error } = control.getFieldState(name);
  return (
    <Box>
      <Text as="label" size="2" htmlFor={name} weight="bold">
        {label}
      </Text>
      <TextField.Root
        mt="1"
        id={name}
        autoComplete={options.autocomplete || 'off'}
        style={error && { boxShadow: 'inset 0 0 0 1px red' }}
        color={error && 'red'}
        {...control.register(name)}
        {...options}
      />
      {error && (
        <Text as="p" color="red" size="1" weight="medium" mt="1">
          {error.message}
        </Text>
      )}
    </Box>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default InputField;

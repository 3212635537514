import axios from 'axios';
import { authClient } from './api';

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
});

export const AUTH_API_URL = 'auth';

export class AuthAPI {
  static async login(credentials) {
    try {
      const response = await client.post(`${AUTH_API_URL}/login`, credentials);
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async registerUser(body) {
    try {
      return await authClient.post(`${AUTH_API_URL}/registration`, body);
    } catch (error) {
      throw error;
    }
  }

  static async changePassword(body) {
    try {
      return await authClient.put(`${AUTH_API_URL}/password`, body);
    } catch (error) {
      const message = error.response.data.error;
      throw new Error(message);
    }
  }
}


import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HOME_ROUTE } from '../../constants';
import { Button, Flex } from '@radix-ui/themes';

const LessonFooter = ({ step, setStep, tasksCount, saveHandler }) => {
  const isLessonEnd = step === tasksCount;

  const moveOnHandler = () => {
    saveHandler();
    setStep(step + 1);
  };

  const isLastTask = step === tasksCount - 1;

  return (
    <Flex justify="end" align="end" gapX="2" ml="5" mt="5">
      {isLessonEnd ? (
        <Link to={HOME_ROUTE}>
          <Button variant="solid" size="3">
            Вернуться на главную
          </Button>
        </Link>
      ) : (
        <>
          <Button variant="outline" size="3" disabled={step === 0} onClick={() => setStep(step - 1)}>
            Назад
          </Button>
          <Button variant="solid" size="3" disabled={isLessonEnd} onClick={moveOnHandler}>
            {isLastTask ? 'Подвести итоги' : 'Вперёд'}
          </Button>
        </>
      )}
    </Flex>
  );
};

LessonFooter.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  tasksCount: PropTypes.number.isRequired,
  saveHandler: PropTypes.func.isRequired,
};

export default LessonFooter;


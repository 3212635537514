import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userAnswers: [],
  selectedAnswers: [],
  lessonTime: 0,
  hasStarted: false,
};

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    selectAnswer: (state, action) => {
      state.selectedAnswers = action.payload;
    },
    setUserAnswers: (state, action) => {
      state.userAnswers = action.payload;
    },
    replaceUserAnswers: (state, action) => {
      const { index, newAnswer } = action.payload;
      state.userAnswers[index] = newAnswer;
    },
    refreshLesson: (state) => {
      state.userAnswers = [];
      state.selectedAnswers = [];
      state.lessonTime = 0;
      state.hasStarted = false;
    },
    setLessonTime: (state, action) => {
      state.lessonTime = action.payload;
    },
    setLessonStatus: (state, action) => {
      state.hasStarted = action.payload;
    },
  },
});

export const { reducer: lessonsReducer, actions: lessonsActions } = lessonsSlice;


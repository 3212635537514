import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lessonsActions } from '../../../../store/slices/lessonsSlice';
import PropTypes from 'prop-types';
import styles from '../Answer.module.scss';
import { Box, TextField } from '@radix-ui/themes';

const MultipleInputAnswer = ({ userAnswerData }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const userAnswers = useSelector((state) => state.lessons.userAnswers);
  const selectedAnswers = useSelector((state) => state.lessons.selectedAnswers);
  const answerIndex = userAnswerData.index;
  const taskPrevAnswers = userAnswers[userAnswerData.taskIndex];
  const taskHasAnswers = Boolean(taskPrevAnswers) && taskPrevAnswers.length > 0;

  const highlightSelectedAnswers = useCallback(() => {
    if (taskHasAnswers) {
      // dispatch(lessonsActions.selectAnswer(taskPrevAnswers));

      const taskPrevAnswer = taskPrevAnswers.find((item) => item.index === answerIndex);

      if (taskPrevAnswer) {
        setValue(taskPrevAnswer.text);
      }
    }
  }, [answerIndex, dispatch, taskHasAnswers, taskPrevAnswers]);

  let updatedAnswers = [];

  useEffect(() => {
    setValue('');

    highlightSelectedAnswers();
  }, [highlightSelectedAnswers]);

  const answerHandler = ({ target }) => {
    setValue(target.value);
    const newAnswer = { ...userAnswerData, text: target.value };

    if (selectedAnswers.length === 0) {
      updatedAnswers = [newAnswer];
    } else {
      const inputIndex = selectedAnswers.findIndex((item) => item.index === newAnswer.index);
      if (inputIndex !== -1) {
        updatedAnswers = [...selectedAnswers];
        updatedAnswers[inputIndex] = newAnswer;
      } else {
        updatedAnswers = [...selectedAnswers, newAnswer];
      }
      updatedAnswers.sort((a, b) => a.index - b.index);
    }

    dispatch(lessonsActions.selectAnswer(updatedAnswers));
  };

  return (
    <div className={`${styles.wrapper} ${styles.wrapper_multiple}`}>
      <label className={styles.label} htmlFor={`inputAnswer-${answerIndex}`}>
        {userAnswerData.text}
      </label>
      <Box maxWidth="500px">
        <TextField.Root
          placeholder="Введите ответ"
          id={`inputAnswer-${answerIndex}`}
          value={value}
          onChange={answerHandler}
          autoComplete="off"
          type="text"
          size="3"
        />
      </Box>
    </div>
  );
};

MultipleInputAnswer.propTypes = {
  userAnswerData: PropTypes.shape({
    index: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    taskIndex: PropTypes.number.isRequired,
    taskNumber: PropTypes.number.isRequired,
  }).isRequired,
};

export default MultipleInputAnswer;


import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LessonAPI, LESSONS_URL } from '../../api/lessonApi';

const lessonsApi = createApi({
  reducerPath: 'lessonsApi',
  baseQuery: fetchBaseQuery({ url: LESSONS_URL }),
  endpoints: (build) => ({
    getLessons: build.query({
      queryFn: LessonAPI.getLessons,
      providesTags: ['lessons'],
    }),
    getLesson: build.query({
      queryFn: LessonAPI.getLessonById,
      providesTags: ['lesson'],
    }),
    getLessonResult: build.query({
      queryFn: LessonAPI.getLessonResult,
    }),
    getLessonAnswers: build.query({
      queryFn: LessonAPI.getLessonAnswers,
    }),

    createLesson: build.mutation({
      queryFn: LessonAPI.createLesson,
      invalidatesTags: ['lessons'],
    }),
    updateLesson: build.mutation({
      queryFn: LessonAPI.updateLesson,
      invalidatesTags: ['lessons', 'lesson'],
    }),
    deleteLesson: build.mutation({
      queryFn: LessonAPI.deleteLesson,
      invalidatesTags: ['lessons'],
    }),
  }),
});

export default lessonsApi;


import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import lessonsApi from '../../store/api/lessonsApi';
import tasksApi from '../../store/api/tasksApi';
import { lessonsActions } from '../../store/slices/lessonsSlice';
import Loader from '../Loader/Loader';
import styles from './LessonResult.module.scss';

const LessonResult = () => {
  const dispatch = useDispatch();
  const { lessonId } = useParams();
  const userData = useSelector((state) => state.users.userData);
  const tasks = tasksApi.useGetTasksQuery(lessonId);
  const userAnswers = useSelector((state) => state.lessons.userAnswers);
  const { userId, firstName, surname, group } = userData;
  const lessonData = { userId, lessonId, userAnswers, tasks: tasks.data, completionTime: 0 };
  const lessonResult = lessonsApi.useGetLessonResultQuery(lessonData);

  useEffect(() => {
    if (!lessonResult.data) {
      dispatch(lessonsActions.setLessonStatus(false));
    }
  }, [dispatch, lessonResult]);

  if (lessonResult.isLoading) {
    return <Loader />;
  }

  const { score, grade, correctAnswerIndexes, wrongAnswerIndexes } = lessonResult.data;

  return (
    <div>
      <h1 className={styles.title}>Итоги</h1>
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.user__info}>
            <p>Студент: {`${surname} ${firstName}`}</p>
            <p>Группа: {group}</p>
          </div>
          <div className={styles.stats}>
            <p className={styles.stats__item_green}>Выполнено: {correctAnswerIndexes.length}</p>
            <p className={styles.stats__item_red}>Ошибок: {wrongAnswerIndexes.length}</p>
            <div className={styles.stats__answers}>
              <h2>Верно выполненные задания:</h2>
              {correctAnswerIndexes.map((item) => item + 1).join(', ')}
            </div>
            <div className={styles.stats__answers}>
              <h2>Неверно выполненные задания:</h2>
              {wrongAnswerIndexes.map((item) => item + 1).join(', ')}
            </div>
          </div>
        </div>
        <div className={styles.result}>
          <h2 className={styles.result__title}>Результативность</h2>
          <h3 className={styles.result__score}>{score}%</h3>
          <h2 className={styles.result__title}>Оценка</h2>
          <h3 className={styles.result__score}>{grade}</h3>
        </div>
      </div>
    </div>
  );
};

export default LessonResult;


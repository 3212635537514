import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TASKS_API_URL, TasksAPI } from '../../api/tasksApi';

const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({ url: TASKS_API_URL }),
  endpoints: (build) => ({
    getTask: build.query({
      queryFn: TasksAPI.getTask,
      providesTags: ['task'],
    }),
    getTasks: build.query({
      queryFn: TasksAPI.getTasks,
      providesTags: ['tasks'],
    }),
    createTask: build.mutation({
      queryFn: TasksAPI.createTask,
      invalidatesTags: ['tasks'],
    }),
    updateTask: build.mutation({
      queryFn: TasksAPI.updateTask,
      invalidatesTags: ['tasks', 'task'],
    }),
    setAnswers: build.mutation({
      queryFn: TasksAPI.setAnswers,
    }),
    deleteTask: build.mutation({
      queryFn: TasksAPI.deleteTask,
      invalidatesTags: ['tasks'],
    }),
  }),
});

export default tasksApi;


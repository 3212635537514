import { authClient } from './api';

export const TASKS_API_URL = 'tasks';

export class TasksAPI {
  static async getTask(taskId) {
    try {
      const response = await authClient.get(`${TASKS_API_URL}/${taskId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getTasks(lessonId) {
    try {
      const response = await authClient.get(`${TASKS_API_URL}?lesson=${lessonId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {File} image
   * @returns {Promise<string>}
   */
  static async storeImage(image) {
    const formData = new FormData();
    formData.append('image', image);

    try {
      const response = await authClient.post(`${TASKS_API_URL}/files`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{taskData: Object, image: File}} taskData
   * @returns {Promise}
   */
  static async createTask({ taskData, image }) {
    const requestBody = { ...taskData };

    if (image && image instanceof File) {
      const response = await TasksAPI.storeImage(image);
      const imagePathId = response.id;
      requestBody.imagePathId = imagePathId;
    }

    try {
      const response = await authClient.post(`${TASKS_API_URL}`, requestBody);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{answers: [], correctAnswers: []}} taskAnswers
   * @returns {Promise}
   */
  static async setAnswers({ taskAnswers, taskId }) {
    try {
      const response = await authClient.post(`${TASKS_API_URL}/${taskId}/answers`, taskAnswers);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {{taskId: number, taskData: Object, image: ?File}} taskData
   * @returns {Promise}
   */
  static async updateTask({ taskId, taskData, image }) {
    const requestBody = { ...taskData };

    if (image && image instanceof File) {
      const response = await TasksAPI.storeImage(image);
      const imagePathId = response.id;
      requestBody.imagePathId = imagePathId;
    }

    try {
      const response = await authClient.put(`${TASKS_API_URL}/${taskId}`, requestBody);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {number} taskId
   * @returns {Promise}
   */
  static async deleteTask(taskId) {
    try {
      const response = await authClient.delete(`${TASKS_API_URL}/${taskId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}


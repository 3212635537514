import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import { HOME_ROUTE, LOGIN_KEY, TOKEN_KEY } from '../../constants';
import authApi from '../../store/api/authApi';
import { authActions } from '../../store/slices/authSlice';
import { usersActions } from '../../store/slices/usersSlice';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const initialValues = { userName: '', password: '' };
  const [login, { isLoading, isError }] = authApi.useLoginMutation();

  const loginUser = async (formValues) => {
    try {
      const {
        data: { token, userData },
      } = await login(formValues);

      sessionStorage.setItem(TOKEN_KEY, token);
      sessionStorage.setItem(LOGIN_KEY, userData.userName);
      dispatch(authActions.setAuth(true));
      dispatch(usersActions.setUserData(userData));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate(HOME_ROUTE, { replace: true });
    }
  }, [isAuth, navigate]);

  return (
    <div className={`${styles.wrapper} container`}>
      <div className={styles.window}>
        <h1 className={styles.title}>Вход на сайт</h1>
        <LoginForm
          defaultValues={initialValues}
          onSubmit={loginUser}
          isLoading={isLoading}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default LoginPage;


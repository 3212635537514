import { Suspense } from 'react';
import PropTypes from 'prop-types';
import LoaderSpinnerContainer from '../components/Loader/LoaderSpinnerContainer';

const LazyComponent = ({ children }) => {
  return <Suspense fallback={<LoaderSpinnerContainer />}>{children}</Suspense>;
};

LazyComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LazyComponent;


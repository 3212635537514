import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AUTH_API_URL, AuthAPI } from '../../api/authApi';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ url: AUTH_API_URL }),
  endpoints: (build) => ({
    register: build.mutation({
      queryFn: AuthAPI.registerUser,
    }),
    login: build.mutation({
      queryFn: AuthAPI.login,
    }),
    changePassword: build.mutation({
      queryFn: AuthAPI.changePassword,
    }),
  }),
});

export default authApi;


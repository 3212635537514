import ContentList from '../../components/ContentList/ContentList';
import styles from './LessonList.module.scss';
import { useParams } from 'react-router-dom';
import coursesApi from '../../store/api/coursesApi';
import lessonsApi from '../../store/api/lessonsApi';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';

const createLessonLink = (item) => `lesson/${item.lessonId}`;

const renderContent = (isLoading, isError, data) => {
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <div>Произошла ошибка</div>;
  }
  if (data?.length === 0) {
    return <div>Ничего не найдено</div>;
  }
  return <ContentList list={data} createLink={createLessonLink} getTitle={(item) => item.lessonName} />;
};

const LessonList = () => {
  const { courseName } = useParams();
  const groupId = useSelector((state) => state.users.userData.groupId);
  const userId = useSelector((state) => state.users.userData.userId);
  const filters = {
    status: 'active',
    group: groupId,
  };
  const courses = coursesApi.useGetCoursesQuery(filters, { refetchOnFocus: false });
  const course = courses.data?.find((course) => course.courseTitle === courseName);
  const lessons = lessonsApi.useGetLessonsQuery({
    courseName,
    filters: { status: 'active', user: userId },
  });

  return (
    <div className={`${styles.wrapper} container`}>
      <div className={styles.window}>
        <h1 className={styles.title}>{course?.courseName}</h1>
        {renderContent(lessons.isLoading, lessons.isError, lessons.data)}
      </div>
    </div>
  );
};

export default LessonList;


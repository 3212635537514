import { ReactComponent as ProfileIcon } from '../../assets/images/icons/account.svg';
import { ReactComponent as CoursesIcon } from '../../assets/images/icons/books.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg';
import { ReactComponent as DashboardIcon } from '../../assets/images/icons/dashboard.svg';
import {
  COURSES_ROUTE,
  DASHBOARD_COURSES_ROUTE,
  REGISTRATION_ROUTE,
  RESULTS_PAGE_ROUTE,
  SETTINGS_ROUTE,
} from '../../constants';

const USER_LINKS = [
  {
    title: 'Список курсов',
    path: COURSES_ROUTE,
    icon: CoursesIcon,
  },
  {
    title: 'Настройки',
    path: SETTINGS_ROUTE,
    icon: SettingsIcon,
  },
];

const TEACHER_LINKS = [
  {
    title: 'Успеваемость',
    path: RESULTS_PAGE_ROUTE,
    icon: ProfileIcon,
  },
  {
    title: 'Панель управления',
    path: DASHBOARD_COURSES_ROUTE,
    icon: DashboardIcon,
  },
];

const ADMIN_LINKS = [
  {
    title: 'Регистрация',
    path: REGISTRATION_ROUTE,
    icon: ProfileIcon,
  },
];

export { USER_LINKS, TEACHER_LINKS, ADMIN_LINKS };


import axios from 'axios';

export const authClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
});

const authInterceptor = (config) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  return newConfig;
};

authClient.interceptors.request.use(authInterceptor);

